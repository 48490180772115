
























































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';

@Component({
  components: { Dropdown },
})
export default class StudentTeacherSearchBar extends Vue {
  @Prop({ default: `Search...` })
  placeholderText!: string;

  @Prop()
  currentSort!: string;

  @Prop()
  currentFilter!: string;

  @Prop()
  previousSearchText!: string;

  searchText: string = '';

  showSortDropdown: boolean = false;
  showFilterDropdown: boolean = false;

  // Sort Column
  selectedSortText = `Sort`;
  selectedSortValue = ``;

  // Sort Column
  selectedFilterText = `Filter`;
  selectedFilterValue = ``;

  get sort(): DropdownItem[] {
    return [
      {
        text: `Sign In (ASC)`,
        value: `signin-asc`,
        uniqueKey: `signin_1`,
      },
      {
        text: `Sign In (DESC)`,
        value: `signin-desc`,
        uniqueKey: `signin_2`,
      },
      {
        text: `Name (ASC)`,
        value: `name-asc`,
        uniqueKey: `name_1`,
      },
      {
        text: `Name (DESC)`,
        value: `name-desc`,
        uniqueKey: `name_2`,
      },
    ];
  }

  get filter(): DropdownItem[] {
    return [
      {
        text: `All`,
        value: `all`,
        uniqueKey: `all_1`,
      },
      {
        text: `Teacher`,
        value: `teacher`,
        uniqueKey: `teacher_1`,
      },
      {
        text: `Admin`,
        value: `admin`,
        uniqueKey: `admin_1`,
      },
      {
        text: `Student`,
        value: `student`,
        uniqueKey: `student_1`,
      },
    ];
  }

  @Watch(`previousSearchText`)
  updateSearchText() {
    if (this.previousSearchText.length > 0) {
      this.searchText = this.previousSearchText;
    }
  }

  @Watch(`currentSort`)
  updateCurrentSort() {
    if (this.currentSort.length > 0) {
      this.selectedSortValue = this.currentSort;
      const index = this.sort.findIndex((data) => {
        return data.value === this.selectedSortValue;
      });
      if (index > -1) {
        this.selectedSortText = this.sort[index].text;
      }
    }
  }

  @Watch(`currentFilter`)
  updateCurrentFilter() {
    if (this.currentFilter.length > 0) {
      this.selectedFilterValue = this.currentFilter;
      const index = this.filter.findIndex((data) => {
        return data.value === this.selectedFilterValue;
      });
      if (index > -1) {
        this.selectedFilterText = this.sort[index].text;
      }
    }
  }

  sortDropdown() {
    this.showSortDropdown = !this.showSortDropdown;
  }

  updateSort(sort: DropdownItem) {
    this.selectedSortText = sort.text;
    this.selectedSortValue = sort.value;
    this.showSortDropdown = !this.showSortDropdown;

    const sortValue = this.selectedSortValue.split('-');
    this.$emit('sort', sortValue[0], sortValue[1]);
  }

  filterDropdown() {
    this.showFilterDropdown = !this.showFilterDropdown;
  }

  @Emit(`filter`)
  updateFilter(filter: DropdownItem) {
    this.selectedFilterText = filter.text;
    this.selectedFilterValue = filter.value;
    this.showFilterDropdown = !this.showFilterDropdown;

    return this.selectedFilterValue;
  }

  @Emit(`search`)
  handleSearch() {
    return this.searchText;
  }
}
