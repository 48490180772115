












































import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import StudentTeacherSearchBar from '@/components/common/StudentTeacherSearchBar.vue';
@Component({
    components: { Page, StudentTeacherSearchBar },
})
export default class Teachers extends Vue {
    //TODO: Integrate with API
    get pageTitle() {
        return `Teachers List`;
    }

    get teachers() {
        return [
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
            {
                teacherId: `0000001`,
                name: `Andrew Chan`,
                gender: `Male`,
                age: 21,
                emailAddress: `andrew123test@test.com`,
                status: `Active`,
            },
        ];
    }

    doSomething(data: any) {}
}
